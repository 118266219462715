<template>
  <div>
    <table class="table table-google">
      <thead>
        <tr>
          <th class="row-fit">#</th>
          <th class="row-fit">Operation</th>
          <th>Hours</th>
          <th>Description</th>
          <th class="row-fit">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(operation,index) in ideal.operations" :key="`operation_${index}`">
          <td>{{ leftPadZero(index + 1, idTargetLength) }}</td>
          <td>
            <b-form-select
              v-model="operation.OperId"
              :options="getOptionsOperId(operation.OperId)"
              size="sm"
              text-field="Name"
              value-field="Id"
              :disabled="loadingCount > 0 || operation.ToDelete"
              class="w-52"
            />
          </td>
          <td>
            <b-form class="flex gap-2 items-center">
              <b-form-group label="Months" label-size="sm">
                <b-form-input
                  v-model="operation.OperHours.months"
                  class="operhours-input"
                  size="sm"
                  type="number"
                  placeholder="Months"
                  required
                  :disabled="loadingCount > 0 || operation.ToDelete"
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Days" label-size="sm">
                <b-form-input
                  v-model="operation.OperHours.days"
                  class="operhours-input"
                  size="sm"
                  type="number"
                  placeholder="Days"
                  required
                  :disabled="loadingCount > 0 || operation.ToDelete"
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Hours" label-size="sm">
                <b-form-input
                  v-model="operation.OperHours.hours"
                  class="operhours-input"
                  size="sm"
                  type="number"
                  placeholder="Hours"
                  required
                  :disabled="loadingCount > 0 || operation.ToDelete"
                ></b-form-input>
              </b-form-group>
            </b-form>
          </td>
          <td>
             <div>
                <b-form-input
                  v-model="operation.Descr"
                  size="sm"
                  placeholder="Description"
                  autocomplete="off"
                  :disabled="loadingCount > 0 || operation.ToDelete"
                >
                </b-form-input>
             </div>
          </td>
          <td class="row-fit">
            <div class="flex gap-2 items-center">
              <b-button
                variant="primary"
                size="sm"
                @click="moveUpArray(index)"
                :disabled="loadingCount > 0 || operation.ToDelete || !canMoveUpArray(index)"
              >
                <i class="fas fa-angle-up"></i>
              </b-button>

              <b-button
                variant="primary"
                size="sm"
                @click="moveDownArray(index)"
                :disabled="loadingCount > 0 || operation.ToDelete || !canMoveDownArray(index)"
              >
                <i class="fas fa-angle-down"></i>
              </b-button>

              <b-button
                variant="danger"
                size="sm"
                @click="deleteOperation(index)"
                :disabled="loadingCount > 0"
              >
                Delete
              </b-button>
            </div>
          </td>
        </tr>

        <tr v-if="ideal.operations.length === 0">
          <td colspan="5">
            <span>No operations available.</span>
          </td>
        </tr>

        <tr>
          <td colspan="5">
            <div class="flex gap-4 justify-between">
              <b-button
                variant="primary"
                size="sm"
                @click="saveIdeal()"
                :disabled="loadingCount > 0 || !canSave"
              >
                Save
              </b-button>

              <b-button
                variant="primary"
                size="sm"
                @click="createOperation()"
                :disabled="loadingCount > 0"
              >
                Add operation
              </b-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  canMoveDownArray,
  canMoveUpArray,
  getArrayTargetLength,
  hasArrayDuplicates,
  leftPadZero,
  moveDownArray,
  moveUpArray,
} from '@/helper';

export default {
  name: 'HerbIdealEditor',
  components: {},
  props: {
    ideal: Object,
    options: Object,
    loadingCount: Number,
  },
  computed: {
    canSave() {
      const operids = this.ideal.operations.map((operation) => operation.OperId);
      if (operids.includes(null)) {
        return false;
      }
      if (hasArrayDuplicates(operids)) {
        return false;
      }
      return true;
    },
    idTargetLength() {
      return getArrayTargetLength(this.ideal.operations.map((operation, index) => index + 1));
    },
  },
  data() {
    return {};
  },
  methods: {
    getOptionsOperId(currentOperId) {
      const takenOperIds = this.ideal.operations.map((operation) => operation.OperId);
      return this.options.OperId.filter((option) => (
        !takenOperIds.includes(option.Id) || option.Id === currentOperId
      ));
    },
    createOperation() {
      this.ideal.operations.push({
        OperId: null,
        Ord: null,
        OperHours: { months: 0, days: 0, hours: 0 },
        Descr: '',
        Name: '',
      });
    },
    deleteOperation(operationIndex) {
      this.ideal.operations.splice(operationIndex, 1);
    },
    moveUpArray(index) {
      moveUpArray(this.ideal.operations, index);
    },
    moveDownArray(index) {
      moveDownArray(this.ideal.operations, index);
    },
    canMoveUpArray(index) {
      return canMoveUpArray(this.ideal.operations, index);
    },
    canMoveDownArray(index) {
      return canMoveDownArray(this.ideal.operations, index);
    },
    saveIdeal() {
      this.$emit('saveIdeal', this.ideal);
    },
    leftPadZero(value, targetLength) {
      return leftPadZero(value, targetLength);
    },
  },
};
</script>

<style lang="scss" scoped>
  .operhours-input {
    width: 70px;
  }
</style>
